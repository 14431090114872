import { Section } from "custom-form-lib"

const startDevSecOpsSection = {
    contentId: "Start",
    onInvalidMessage: "Starten",
    onValidMessage: "Starten",
    formGroupType: "BooleanInputControl",
    options: [`
      <div class="text-[14px] 600:text-[14px] 1200:text-[16px] 1500:text-[22px] dark:text-[#B6C4C9]">
        Bitte stimme kurz der
        <span >
          <a style="text-decoration-skip-ink: none" class="underline  dark:text-white" rel="noopener noreferrer" target="_blank"
            onclick="event.stopPropagation();"
            href="https://developerakademie.com/data-protection">Datenschutzerklärung</a>
        </span> zu.
      </div>`
    ], //title per option
    required: true,
    formControlNames: ["accept"], //one formControlName per option
    // title: `Starte deine Karriere als <span class="text-orange">DevSecOps</span>`
    onInvalidMessages: ["Bitte akzeptiere die Datenschutzerklärung."]
} as Section;

const introductionSection = {
    contentId: "Introduction",
    formGroupType: "TextInputControl",
    options: ["Dein Vorname", "Dein Nachname"],
    formControlNames: ["firstName", "lastName"],
    required: true,
    title: `
    <p class="text-[22px] font-bold font-figtree
      900:text-[28px]">
      <span class="text-orange">
        Schön, dass Du hier bist.
      </span> Bitte stelle dich kurz vor.
    </p>`
} as Section;

const ageSection = {
    formGroupType: "SingleChoiceControl",
    options: ["18-25", "26-35", "36-45", "46+"],
    formControlNames: ["age"],
    required: true,
    title: `Wie alt bist du?`
} as Section;

const professionSection = {
    formGroupType: "SingleChoiceControl",
    options: [
        "Schüler / Schülerin",
        "Student / Studentin",
        "Angestellter / Angestellte",
        "Selbständig",
        "Arbeitsuchend",
        "Sonstiges"
    ],
    formControlNames: ["profession"],
    required: true,
    title: `Dein aktueller Berufsstand`
} as Section;

const experienceSection = {
    formGroupType: "SingleChoiceControl",
    formControlNames: ["experience"],
    options: [
        "Ich habe keine Programmiererfahrung.",
        "Ich habe Grundkenntnisse in mindestens einer Programmiersprache.",
        "Ich programmiere regelmäßig, besonders in Python.",
        "Ich bin erfahrener Entwickler mit Kenntnissen in mehreren Sprachen."
    ],
    title: "Welche Programmiererfahrung bringst du mit?",
    required: true
} as Section;

const desiresSection = {
    formGroupType: "MultipleChoiceControl",
    formControlNames: ["desires"],
    options: [
        "Die Integration von Sicherheit in den Entwicklungsprozess.",
        "Die Automatisierung von Entwicklungs- und Bereitstellungsprozessen.",
        "Die Arbeit mit Cloud-Technologien und Containern.",
        "Die Optimierung der Zusammenarbeit zwischen Entwicklung und IT-Betrieb."
    ],
    title: "Was interessiert dich am meisten an DevSecOps?",
    required: true
} as Section;

const devSecOpsExperienceSection = {
    formGroupType: "MultipleChoiceControl",
    formControlNames: ["devSecOpsExperience"],
    options: [
        "Ich habe noch keine Erfahrung mit DevOps oder DevSecOps.",
        "Ich habe bereits Erfahrung mit DevOps Tools wie Github Actions, Jenkins CI, GitLab CI.",
        "Ich verfüge über Erfahrung mit CI/CD und Container-Technologien.",
        "Ich habe bereits mit Cloudtechnologien gearbeitet.",
    ],
    title: "Welche der folgenden Aussagen beschreiben deine Erfahrungen  im Bereich DevSecOps?",
    required: true
} as Section;

const willSection = {
    onInvalidMessage: "Bitte ausfüllen",
    formGroupType: "TextAreaInputControl",
    formControlNames: ["will"],
    options: [
        "Gib hier deine Antwort ein..."
    ],
    required: true,
    title: "Was motiviert dich an einer Weiterbildung in DevSecOps?",
    maxLength: [254]
} as Section;

const emailSection = {
    contentId: "EmailAddress",
    onInvalidMessage: "Bitte ausfüllen",
    formGroupType: 'EmailInputControl',
    formControlNames: ['email'],
    options: [
        "Deine E-Mail Adresse"
    ],
    title: 'Deine E-Mail für die Terminbestätigung',
    required: true,
} as Section;

const phoneNumberSection = {
    formGroupType: "PhoneInputControl",
    formControlNames: ["phoneNumber"],
    options: ["012345678901"],
    preselectedValues: ["+49"],
    title: "Unter welcher Telefonnummer können wir Dich für das Beratungsgespräch erreichen?",
    required: true
} as Section;

const reachableSection = {
    formGroupType: "SingleChoiceControl",
    formControlNames: ["reachable"],
    options: [
        "Morgens, zwischen 8-12 Uhr",
        "Nachmittags, zwischen 13-17 Uhr",
        "Abends, zwischen 17-20 Uhr"
    ],
    title: "Wann bist Du in der Regel am besten (telefonisch) erreichbar?",
    required: true
} as Section;

const leadSourceSection = {
    formGroupType: "MultipleChoiceControl",
    formControlNames: ["leadSource"],
    options: [
        'Suchmaschine (z.B. Google, Bing)', 'Youtube', 'Instagram', 'Facebook', 'TikTok', 'Podcast', 'andere Teilnehmer/Alumni'
    ],
    title: "Wie bist du auf die Developer Akademie aufmerksam geworden?",
    required: true,
    optionalInput: "Sonstiges",
    onValidMessage: "Übermitteln",
} as Section;

export const devsecopsSections = [
    startDevSecOpsSection,
    introductionSection,
    ageSection,
    professionSection,
    experienceSection,
    desiresSection,
    devSecOpsExperienceSection,
    willSection,
    emailSection,
    phoneNumberSection,
    reachableSection,
    leadSourceSection
];
